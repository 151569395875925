import { useToast } from 'vue-toastification'
import getAllRoutes from './AppApiRoutes'
import { useApiErrorsStore, fetchToApiError } from '~~/store/api_errors'

import { TypePlaceEnum } from '~~/composables/useMenuModel'
export const useCreatePlace = () => {
  const toast = useToast()
  const { t } = useNuxtApp().$i18n
  const { placeModel } = useMenuModel()
  const typePlace = ref<TypePlaceEnum>(TypePlaceEnum.House)
  const form = reactive({
    details: '',
    json_address: undefined as any,
    street: '',
    building: '',
    city_id: undefined,
    neighborhood_id: undefined,
    text_address: '',
    floor: '',
    apartment: '',
    postal_code: '',
    sourceType: 'App\\Models\\Agent'
  })

  const { addApiError, deleteApiError } = useApiErrorsStore()
  const loadingCreatePlace = ref<boolean>(false)
  async function createPlace(): Promise<any> {
    const { status } = useAuth()

    if (status.value !== 'authenticated') {
      toast.error(t('login_required'), { timeout: 1000 })
      return false
    }
    if (!form.text_address.trim()) {
      form.text_address = getPlaceTitleByTypePlace(typePlace.value)
    }
    if (!form.city_id) {
      toast.error(
        t('required', {
          name: t('city')
        }),
        { timeout: 1000 }
      )
      return false
    }
    if (!form.json_address) {
      toast.error(
        t('required', {
          name: t('location_from_map')
        }),
        { timeout: 1000 }
      )
      return false
    }
    try {
      deleteApiError('agent_place')
      loadingCreatePlace.value = true
      const data = await useBasicFetch(
        getAllRoutes().placesRoutes.newAgentPlaceByServer,
        {
          method: 'POST',
          body: {
            app_locale: useNuxtApp().$i18n?.locale?.value,
            ...form
          }
        }
      )

      toast.success(t('create_done'), { timeout: 1000 })
      loadingCreatePlace.value = false
      return placeModel(data.data)
    } catch (error) {
      toast.error(t('create_failed'), { timeout: 1000 })
      loadingCreatePlace.value = false
      addApiError(fetchToApiError(error), 'agent_place')
      return false
    }
  }
  function getPlaceTitleByTypePlace(type: TypePlaceEnum): string {
    switch (type) {
      case TypePlaceEnum.House:
        return 'منزل'
      case TypePlaceEnum.Work:
        return 'عمل'
      default:
        return 'أخر'
    }
  }
  function getPlaceTitleTranslatedByTypePlace(type: TypePlaceEnum): string {
    switch (type) {
      case TypePlaceEnum.House:
        return t('house')
      case TypePlaceEnum.Work:
        return t('work')
      default:
        return t('other')
    }
  }

  function getTypePlaceByTitle(title: string): TypePlaceEnum {
    if (title === 'منزل') {
      return TypePlaceEnum.House
    }
    if (title === 'عمل') {
      return TypePlaceEnum.Work
    }
    return TypePlaceEnum.Other
  }
  function clearErrorApi() {
    deleteApiError('agent_place')
  }

  return {
    form,
    typePlace,
    clearErrorApi,
    getPlaceTitleByTypePlace,
    getTypePlaceByTitle,
    loadingCreatePlace,
    getPlaceTitleTranslatedByTypePlace,
    createPlace
  }
}

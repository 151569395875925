<template>
  <div class="w-screen max-w-3xl bg-bg_alt_color">
    <div class="flex flex-wrap p-3 w-11/12 md:w-full">
      <div class="flex-auto p-2 w-full md:w-7/12">
        <client-only>
          <MyAccountPlacesGoogleMap
            error="agent_place-json_address"
            :enable-search="true"
            class="mx-auto w-full"
            @geo-location="updateLocation($event)"
          />
          <div v-if="textErrorSelectorPlace" class="text-red-500 text-sm">
            {{ textErrorSelectorPlace }}
          </div>
        </client-only>
      </div>

      <div class="w-full md:w-5/12 p-2">
        <MyAccountPlacesLocationType v-model="updateTypePlace" />
        <AuthTextField
          v-if="typePlace == TypePlaceEnum.Other"
          v-model="form.text_address"
          :view-label="false"
          :placeholder="t('other')"
          type="text"
          margin="mb-2"
          input-class="text-center text-sm"
          error="agent_place-text_address"
        />
        <div class="flex justify-between">
          <MyAccountPlacesCitiesDropDown
            v-model="form.city_id"
            error="agent_place-city_id"
            :disabled="disabledDropDownCities"
          />
          <MyAccountPlacesNeighborhoodsDropDown
            v-model="form.neighborhood_id"
            :city-id="form.city_id"
          />
        </div>

        <div class="sm:flex justify-between">
          <div class="sm:w-6/12">
            <AuthTextField
              v-model="form.street"
              :placeholder="t('street')"
              margin=""
              class="sm:w-6/12"
              type="text"
              :view-label="false"
              input-class="text-center text-sm"
              error="agent_place-street"
            />
          </div>
          <div class="w-2 hidden sm:block"></div>
          <div class="sm:w-6/12">
            <AuthTextField
              v-model="form.building"
              :placeholder="t('building')"
              margin=""
              class="flex-1"
              type="text"
              :view-label="false"
              input-class="text-center text-sm"
              error="agent_place-building"
            />
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex-1">
            <AuthTextField
              v-model="form.floor"
              :placeholder="t('floor')"
              type="text"
              :view-label="false"
              margin=""
              input-class="text-center text-sm"
              error="agent_place-floor"
            />
          </div>
          <div class="w-2"></div>
          <div class="flex-1">
            <AuthTextField
              v-model="form.apartment"
              :placeholder="t('apartment')"
              :view-label="false"
              margin=""
              type="text"
              input-class="text-center text-sm"
              error="agent_place-apartment"
            />
          </div>
        </div>
        <AuthTextField
          v-model="form.details"
          :placeholder="t('details')"
          margin=""
          type="text"
          :view-label="false"
          input-class="text-center text-sm"
          error="agent_place-details"
        />
      </div>
    </div>

    <!-- Submit -->
    <MevcutResponseError
      class="my-2"
      api-key="agent_place"
      :except="[
        'agent_place-details',
        'agent_place-json_address',
        'agent_place-street',
        'agent_place-building',
        'agent_place-floor',
        'agent_place-postal_code',
        'agent_place-apartment'
      ]"
    />
    <div class="w-40 mx-auto">
      <MevcutSubmitButton
        class="my-2"
        :title="t('create_address')"
        :loading="loadingCreatePlace"
        @submit="createAddress"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
import { TypePlaceEnum } from '~~/composables/useMenuModel'
const { form, loadingCreatePlace, typePlace, createPlace, clearErrorApi } =
  useCreatePlace()
const { addNewPlace, getCityByCityId } = usePlacesStore()
const { t } = useI18n()

const emit = defineEmits(['created'])
const props = defineProps<{
  initailCityId?: number
}>()
clearErrorApi()
const initailCity = computed(() => {
  return getCityByCityId(props.initailCityId)
})
const disabledDropDownCities = ref<boolean>(false)
const textErrorSelectorPlace = ref<string | undefined>(undefined)
if (props.initailCityId) {
  form.city_id = props.initailCityId as any
  disabledDropDownCities.value = true
}
async function createAddress() {
  if (textErrorSelectorPlace.value) {
    return
  }
  const newAddress = await createPlace()

  if (newAddress) {
    form.details = ''
    form.street = ''
    form.building = ''
    form.city_id = undefined
    form.neighborhood_id = undefined
    form.text_address = ''
    form.floor = ''
    form.apartment = ''
    form.postal_code = ''
    addNewPlace(newAddress)
    emit('created', newAddress)
  }
}
const locale = useNuxtApp().$i18n?.locale?.value
async function updateLocation(event: any) {
  form.neighborhood_id = undefined
  textErrorSelectorPlace.value = undefined
  const { data: dataTemp } = await useBasicFetch(
    getAllRoutes().placesRoutes.convertGoogleAddressToLocal,
    {
      method: 'POST',
      body: {
        app_locale: locale,
        lat: event.lat,
        lng: event.lng
      }
    }
  )
  const newCityId = dataTemp.value?.data?.city?.id
  if (props.initailCityId && props.initailCityId != newCityId) {
    form.json_address = undefined
    textErrorSelectorPlace.value = t('error_wrong_city_from_map', {
      city: initailCity.value?.name
    })
    return
  }
  form.json_address = JSON.stringify(event)
  form.city_id = props.initailCityId || newCityId
  disabledDropDownCities.value = !!form.city_id
}

const updateTypePlace = computed({
  get() {
    return typePlace.value
  },

  set(value: TypePlaceEnum) {
    if (value !== TypePlaceEnum.Other) {
      form.text_address = ''
    }
    typePlace.value = value
  }
})
</script>

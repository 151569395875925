<template>
  <div class="flex justify-center mb-2">
    <div
      v-for="type in typesPlaces"
      :key="`cart-material-iteml-lookupValue-${type}`"
      class="location-type"
    >
      <div
        :class="
          selectedType(type) ? 'bg-primary text-white' : 'bg-white text-black'
        "
        class="flex items-center p-1 rounded-lg mr-2 shadow-md cursor-pointer transition-colors duration-300"
        @click="selectType(type)"
      >
        <v-icon :icon="getIconClass(type)" :size="20" />
        <div class="w-1"></div>
        <span class="text-lg">{{ getPlaceTitleTranslatedByTypePlace(type) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts"></script>

<script setup lang="ts">
import { TypePlaceEnum } from '~~/composables/useMenuModel'

const props = withDefaults(
  defineProps<{
    label: string
    modelValue: TypePlaceEnum
    classText?: string
    disabled: boolean
    error?: string | undefined
  }>(),
  {
    label: '',
    classText: 'mevcut-text-field',
    disabled: false,
    error: undefined
  }
)
const { getPlaceTitleTranslatedByTypePlace } = useCreatePlace()
const emit = defineEmits(['update:modelValue'])
const typesPlaces = [
  TypePlaceEnum.House,
  TypePlaceEnum.Work,
  TypePlaceEnum.Other
]

const selectType = (e: TypePlaceEnum) => {
  emit('update:modelValue', e)
}
const selectedType = (e: TypePlaceEnum) => {
  return props.modelValue === e
}

function getIconClass(type: TypePlaceEnum): string {
  switch (type) {
    case TypePlaceEnum.House:
      return 'mdi mdi-home'
    case TypePlaceEnum.Work:
      return 'mdi mdi-briefcase'
    default:
      return 'mdi mdi-vote'
  }
}
</script>

<template>
  <div v-if="neighborhoods?.length" class="flex-1 rtl:pr-2 ltr:pr-2">
    <v-menu v-model="isMenuOpen">
      <template #activator="{ props }">
        <div
          class="bg-bg_color hover:bg-nav_color hover:bg-opacity-10 p-2 rounded"
        >
          <div v-bind="props" class="cursor-pointer flex justify-between">
            <div class="flex">
              <v-icon icon="mdi mdi-map-marker" :size="24" />
              <div
                class="line-clamp-1"
                :class="{
                  'text-text_color text-opacity-60': !selectedNeighborhood?.name
                }"
              >
                {{ selectedNeighborhood?.name || t('neighborhood') }}
              </div>
            </div>
            <v-icon icon="mdi mdi-menu-down" :size="24" />
          </div>
        </div>
      </template>
      <v-list class="dropdown-menu">
        <div
          v-for="(neighborhood, index) in neighborhoods"
          :key="`locale-item ${index}`"
          class="my-1 mx-3 text-center cursor-pointer"
          @click="selectNeighborhood(neighborhood)"
        >
          <div
            :class="{
              'bg-bg_color': neighborhood.id.toString() == modelValue?.toString()
            }"
          >
            {{ neighborhood.name }}
          </div>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
const { t } = useI18n()
const props = defineProps<{
  modelValue?: number
  cityId?: number
}>()
const isMenuOpen = ref<boolean>(false)
const neighborhoods = ref<City[]>([])
const selectedNeighborhood = computed(() => {
  return neighborhoods.value?.find((city) => city.id === props.modelValue)
})

async function fetchNeighborhoods() {
  const { data: neighborhoodsTemp } = await useBasicFetch(
    getAllRoutes().citiesRoutes.getNeighborhoods,
    {
      query: { city_id: props.cityId }
    }
  )
  neighborhoods.value = neighborhoodsTemp.value?.data || []
}
if (props.cityId) {
  await fetchNeighborhoods()
}

function selectNeighborhood(neighborhood: City) {
  emit('update:modelValue', neighborhood.id)
  isMenuOpen.value = false
}
const emit = defineEmits<{
  (event: 'update:modelValue', value?: number): void
}>()
watch(
  () => props.cityId,
  async (value) => {
    if (value) {
      await fetchNeighborhoods()
    } else {
      neighborhoods.value = []
      emit('update:modelValue', undefined)
    }
  }
)
</script>
<style scoped>
.dropdown-menu {
  @apply overflow-y-auto bg-bg_color mt-2 text-text_color  max-h-48;
}
</style>
